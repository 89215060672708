<template>
  <v-row>
    <listRowCard
      v-for="(row, rowIndex) in listData"
      :key="`list-row-${rowIndex}`"
      :row="row"
      :index="rowIndex"
      :listKey="listKey"
    ></listRowCard>
  </v-row>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    listKey: String,
  },
  computed: {
    listData() {
      return this.$store.getters[`list/${this.listKey}/data`]
    },
  },
  components: {
    listRowCard: () => import('components/list/cardMode/listRowCard.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>